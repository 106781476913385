import appConfig from '@config/app'

const careerPage =
    appConfig.environment === 'production'
        ? 'https://career.base.co.id'
        : 'https://dev.career.base.co.id'

const resellerPage =
    appConfig.environment === 'production'
        ? 'https://reseller.base.co.id'
        : 'https://dev.reseller.base.co.id'

export const aboutBaseLink = t => {
    return [
        {
            link: '/quality',
            text: t("common:footer.link.about-base.our-promise"),
        },
        {
            link: '/greencollective',
            text: 'Green Collective',
        },
        {
            link: '/clean-standard',
            text: 'Clean Beauty',
        },
        {
            link: '/blog',
            text: 'Blog',
        },
        {
            link: '/gift-card',
            text: 'Gift Card',
        },
        {
            link: '/back-to-baseic',
            text: '#BacktoBASEic',
        },
        {
            link: resellerPage,
            text: t("common:footer.link.about-base.reseller-program"),
        },
        {
            link: 'https://drive.google.com/file/d/1s4BFBZCjVm8VHVEqawwC2xSa78_Zg0V8/view?usp=sharing',
            text: t("common:footer.link.about-base.offline-store"),
            hideOnMobile: true,
        },
    ]
}

export const customerSupportLinks = t => {
    return [
        {
            link: '/contact-us',
            text: t("common:footer.link.customer-support.customer-complaint"),
        },
        {
            link: '/faq',
            text: 'FAQ',
        },
        {
            link: '/faq',
            text: t("common:footer.link.customer-support.shipping-return"),
        },
        {
            link: '/privacy-policy',
            text: t("common:footer.link.customer-support.privacy-policy"),
        },
        {
            link: '/term-of-use',
            text: t("common:footer.link.customer-support.terms-use"),
        },
    ]
}

export const joinBaseLinks = t => {
  return [
    {
        link: careerPage,
        text: t("common:footer.link.join-base.career"),
    },
    {
        link: '/bff',
        text: t("common:footer.link.join-base.bff-affiliate"),
    },
]}

export const findUsLinks = [
    {
        image: 'https://storage.googleapis.com/base-lp-assets/shopee_.svg',
        link: 'https://shopee.co.id/itsmybase',
    },
    {
        image: 'https://storage.googleapis.com/base-lp-assets/tokopedia_.svg',
        link: 'https://www.tokopedia.com/itsmybase',
    },
    {
        image: 'https://storage.googleapis.com/base-lp-assets/lazada_.svg',
        link: 'https://s.lazada.co.id/s.xA8Ff',
    },
    {
        image: 'https://storage.googleapis.com/base-lp-assets/blibli_.svg',
        link: 'https://www.blibli.com/brand/base',
    },
    {
        image: 'https://storage.googleapis.com/base-lp-assets/beautyhaul.svg',
        link: 'https://www.beautyhaul.com/brand/base',
    },
    {
        image: 'https://storage.googleapis.com/base-lp-assets/femaledaily.svg',
        link: 'https://studio.femaledaily.com/brands/base',
    },
]

export const contactUsLinks = [
    {
        title: 'Whatsapp',
        link: 'https://wa.me/+6281808110888',
        caption: '+6281808110888',
    },
    {
        title: 'Email',
        link: 'mailto:hello@base.co.id',
        caption: 'hello@base.co.id',
    },
  ]

export const socialMediaLinks = [
    {
        image: 'https://storage.googleapis.com/base-lp-assets/instagram_black.svg',
        link: 'https://www.instagram.com/itsmybase/',
    },
    {
        image: 'https://storage.googleapis.com/base-lp-assets/tiktok_black.svg',
        link: 'https://www.tiktok.com/@itsmybase',
    },
    {
        image: 'https://storage.googleapis.com/base-lp-assets/youtube_black.svg',
        link: 'https://www.youtube.com/@itsmybase',
    },
    {
        image: 'https://storage.googleapis.com/base-lp-assets/x_black.svg',
        link: 'https://twitter.com/itsmybase',
    },
    {
        image: 'https://storage.googleapis.com/base-lp-assets/linkedin_black.svg',
        link: 'https://www.linkedin.com/company/baseid',
    },
  ]
